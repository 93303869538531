:root {
  --radio-switch-width: 196px;
  --radio-switch-height: 46px;
  --radio-switch-padding: 3px;
  --radio-switch-radius: 0.25rem;

  /* animation */
  --radio-switch-animation-duration: 0.3s;
}

.radio-switch {
  background-color: rgba(0,0,0,.05);
  display: inline-flex;
  padding: var(--radio-switch-padding);
  border-radius: var(--radio-switch-radius);
  width: 400px;
}
.radio-switch:focus-within {
  box-shadow: 0 0 5px 1px rgba(108, 117, 125, 0.5);
}

.radio-switch__item {
  position: relative;
  min-height: calc(
    var(--radio-switch-height) - 2 * var(--radio-switch-padding)
  );
  width: 100%;
  text-align: center;
}

.radio-switch__input {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.radio-switch__label {
  position: relative;
  z-index: 1;
  line-height: calc(
    var(--radio-switch-height) - 2 * var(--radio-switch-padding)
  );
  text-align: center;
  border-radius: var(--radio-switch-radius);
  height: 100%;
}
.radio-switch__input:checked ~ .radio-switch__label {
  color: black;
  font-smooth: auto;
  -webkit-font-smoothing: auto;
  transform: color var(--radio-switch-animation-duration);
}

.radio-switch__marker {
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 8px rgb(0 0 0 / 15%);
  transition: transform var(--radio-switch-animation-duration);
}

.radio-switch__input:checked ~ .radio-switch__marker {
  transform: translateX(100%);
}
