* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@font-face {
  font-family: 'GTEesti';
  src: local('GTEesti'), url(/public/gt-eesti-display-regular-trial-webfont.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');


.lora-font {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

body {
  padding: 0;
  margin: 0;
  color: #f4f1de;
  font-family: "Lora", Times, serif !important;
  letter-spacing: 2px;
}

#logo {
  height: 50px;
}

#onefold {
  height: 150px;
}

.nav-link {
  font-size: 20px;
  font-weight: 700;
}

.main-container {
  min-height: calc(100vh - 176px);
}

.footer {
  height: 50px;
}

.nav-link{
    color:#3d405b !important;
}

.btn-secondary:hover{
   background-color: #e07a5f !important;
}
.btn-secondary {
    color: #f4f1de !important;
    background-color: #3d405b !important;
    border-color: #3d405b !important;
}
